import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Heading,
  Container,
  Text,
  Link,
  Flex
} from '@chakra-ui/react'
import { PrismicRichText } from "@prismicio/react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import SliceZone from "../components/SliceZone/SliceZone"


const NewsPageTemplate = (props) => {
    const { data, location, pageContext } = props;
    const newsItems = data.prismicNewsAndEvents.data
    const { prev, next } = pageContext
    const document = data.prismicNewsAndEvents.data
    const pageLocation = location.pathname;

    return (
      <Layout pageLocation={pageLocation}>
      <Seo title={newsItems.title.text} />
      {/* <Box h={{base: 2, lg: 4}} bg="sage.500" /> */}

      <Container maxW="container.lg">
        <Heading as="h1" variant="h1" mt="12" textAlign="center" color="sage.700">
          {newsItems.title.text}
        </Heading>
        <Text textAlign="center">
            Published {data.prismicNewsAndEvents.last_publication_date}
        </Text>
        <Container maxWidth="container.md"><GatsbyImage image={getImage(newsItems.featured_image.localFile)} alt={newsItems.featured_image.alt} /></Container>
        <Box mt="12">
          <PrismicRichText field={newsItems.content.richText} />
        </Box>
        <Box>
          <SliceZone sliceZone={document.body} />
        </Box>
        <Flex
      style={{clear: 'both'}}
            py="8"
            justifyContent="space-between"
            flexWrap="wrap"
            textAlign="center"
            
          >
            <Box mb={{ sm: 4, md: 0 }}>
              {prev && (
                <Link
                  to={`/news/${prev.uid}`}
                  as={GatsbyLink}
                  variant="blog-link"
                  color="bold-green.600"
                  _hover={{ color: 'sage.500' }}
                >
                  <ChevronLeftIcon fontSize="1.5rem" />
                  {prev.data.title.text}
                </Link>
              )}
            </Box>
            <Box mb={{ sm: 4, md: 0 }}>
              {next && (
                <Link
                  to={`/news/${next.uid}`}
                  as={GatsbyLink}
                  variant="blog-link"
                  color="bold-green.600"
                  _hover={{ color: 'sage.500' }}
                >
                  {next.data.title.text}
                  <ChevronRightIcon fontSize="1.5rem" />
                </Link>
              )}
            </Box>
          </Flex>
      </Container>


    </Layout>
  )
}

export const query = graphql`
query NewsEventsQuery($uid: String!) {
prismicNewsAndEvents(uid: {eq: $uid}) {
  uid
  data {
    title {
      text
    }
    exceprt
    event_date(formatString: "MMMM DD YYYY")
    content {
      richText
    }
    featured_image {
      alt
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    body {
      ... on PrismicNewsAndEventsDataBodyRichTextSlice {
        id
        slice_type
        primary {
          content1 {
            richText
          }
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        slice_label
      }
    }
  }
  last_publication_date(formatString: "MMMM DD, YYYY")
}
}
`


export default NewsPageTemplate
